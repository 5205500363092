import React, { MouseEventHandler, useState } from "react";
import { useTranslation } from 'react-i18next';
import { NextArrow, PrevArrow, SortingArrows } from "../common/ProjectIcons";
import { ProviderType } from "../common/ObjectTypes";
import NoResults from "../common/NoResults";
import Pagination from "../common/Pagination";
import { page_size } from "../../api/apiUtils";
import Spinner from "../common/Spinner";

type StepOneProps = {
    providerTypes: ProviderType[],
    reloadProviderTypes: boolean,
    onSelectProviderType: Function,
    onSearchProviderType: Function,
    onPageClickProviderTypes: MouseEventHandler,
    providerTypesTotal: number,
    providerTypesOffset: number,
    pageLoaded: boolean
}

const NewConnectionStepOne = ({
    providerTypes,
    reloadProviderTypes,
    onSelectProviderType,
    onSearchProviderType,
    onPageClickProviderTypes,
    providerTypesTotal,
    providerTypesOffset,
    pageLoaded
}: StepOneProps) => {
    const { t } = useTranslation();
    const [subStr, setSubStr] = useState("");
    const [selectedType, setSelectedType] = useState("");
    return (
        <div className="mt-8">
            {/* div with text and filters */}
            <div className="flex flex-col justify-between w-full mb-5">
                <label className="text-off-black text-lg">Select the data provider you wish to connect to.</label>
                <input
                    id="search"
                    placeholder="Search provider types"
                    value={subStr}
                    className="p-1.5 w-80 h-auto mt-4 border border-neutral-gray-200 rounded rounded-sm px-2"
                    onChange={(e) => {
                        onSearchProviderType(e)
                        setSubStr(e.target.value)
                    }}
                >
                </input>
            </div>
            <div className="flex flex-row">
                {reloadProviderTypes ?
                    <div className="w-2/3">
                        <Spinner />
                    </div>
                    :
                    <div className="w-2/3">
                        {providerTypes.length < 1 &&
                            <NoResults
                                headerMsg="No Provider Types Found"
                                labelMsg="You may need to contact system admin."
                            />}
                        {providerTypes.length >= 1 &&
                            <table className="table-auto w-full cursor-pointer border border-gray-lines text-off-black rounded-md bg-very-light-gray">
                                <thead className="px-3 pt-3 pb-2 font-bold uppercase text-sm bg-light-gray text-left">
                                    <tr>
                                        <th className="p-3">
                                            Provider
                                            <span><SortingArrows /></span>
                                        </th>
                                        <th className="p-3">
                                            Type of Data
                                        </th>
                                        <th className="p-3">
                                            Website
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {providerTypes.map((pt: ProviderType) => {
                                        return (
                                            <tr key={pt.id} className={selectedType === pt.id ? "border border-blue-basic" : "hover:bg-light-blue"}
                                                onClick={() => {
                                                    onSelectProviderType(pt)
                                                    setSelectedType(pt.id)
                                                }}
                                            >
                                                <td className="p-3">
                                                    {pt.name}
                                                </td>
                                                <td className="p-3">
                                                    -------
                                                </td>
                                                <td className="p-3">
                                                    <a href="https://support.earthranger.com/en_US/gundi-integrations" target="_blank" className="text-blue-basic">Learn More</a>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>}
                        <Pagination
                            showText={true}
                            handlePageClick={onPageClickProviderTypes}
                            pageCount={Math.ceil(providerTypesTotal / page_size)}
                            resultStart={providerTypesOffset + 1}
                            resultEnd={(providerTypesOffset + page_size) < providerTypesTotal ? (providerTypesOffset + page_size) : providerTypesTotal}
                            totalCount={providerTypesTotal}
                            take={page_size}
                            loading={!pageLoaded}
                            mainclassname="p-6 pt-2 w-full flex flex-row justify-between bg-very-light-gray opacity-80"
                            initialPage={Math.floor(providerTypesOffset / page_size)}
                        />
                    </div>}
                <div className="border border-gray-lines ml-4 py-4 px-5 w-1/3 h-fit bg-white">
                    <p className="text-lg text-medium-gray mb-4">Can't find the data source you're looking for?</p>
                    <p>We are constantly working on integrating with new technologies. Please reach out to info@projectgundi.org with any requests.</p>
                </div>
            </div>
        </div>
    );
};

export default NewConnectionStepOne
import React, { ChangeEventHandler, useState } from "react";
import { useTranslation } from 'react-i18next';
import { AttentionIcon, SortedDown, SortedUp, SortingArrows } from "../common/ProjectIcons";
import { Destination, RoutingRule, Source } from "../common/ObjectTypes";
import SourceFilter from "../sources/SourcesFilter";
import NoResults from "../common/NoResults";
import Pagination from "../common/Pagination";
import Spinner from "../common/Spinner";

type ConnectionSourceProps = {
    sources: Source[],
    totalSources: number,
    filters: any[],
    take: number,
    offset: number,
    onPageClicked: Function,
    loadingSources: boolean,
    pageLoaded: boolean,
    destinationUrls: string[],
    onChangeGlobalSourceSearch: Function,
    onChangeSearchUrlFilter: ChangeEventHandler,
    onChangeDestinationUrlSearchBar: ChangeEventHandler,
    onRemoveSourceFilterTag: Function,
    onResetSourceFilters: Function,
    requestSortSources: Function,
    loadingDestinationUrls: boolean,
    loadMoreDestinationUrls: boolean,
    onLoadMoreDestinationUrls: Function
}

const ConnectionDetailsSource = ({
    sources,
    totalSources,
    filters,
    take,
    offset,
    onPageClicked,
    pageLoaded,
    loadingSources,
    destinationUrls,
    onChangeGlobalSourceSearch,
    onChangeSearchUrlFilter,
    onChangeDestinationUrlSearchBar,
    onRemoveSourceFilterTag,
    onResetSourceFilters,
    requestSortSources,
    loadingDestinationUrls,
    loadMoreDestinationUrls,
    onLoadMoreDestinationUrls
}: ConnectionSourceProps) => {
    const { t } = useTranslation();
    const [sortDirection, setSortDirection] = useState(false);
    const [sortByColumn, setSortByColumn] = useState("external_id");
    return (
        <div className="mt-8">
            {/* div with text and add button */}
            <div className="flex flex-row justify-between items-center w-full">
                <label className="text-secondary-gray">The individual sets of data coming from the provider.</label>

            </div>
            {/* div with search bar and table*/}
            <div className="flex flex-row mr-2 my-3">
                <SourceFilter
                    filters={filters}
                    organizations={[]}
                    providerTypes={[]}
                    destinationTypes={[]}
                    destinationUrls={destinationUrls}
                    onChangeEndpoint={onChangeSearchUrlFilter}
                    onChangeDestinationUrlSearchBar={onChangeDestinationUrlSearchBar}
                    onChangeProviderTypeSearchBar={() => console.log("searching provider type wip")}
                    onChangeGlobalSearch={onChangeGlobalSourceSearch}
                    onRemoveTag={onRemoveSourceFilterTag}
                    onResetFilters={onResetSourceFilters}
                    showOrgFilter={false}
                    showProviderTypeFilter={false}
                    showDestinationTypeFilter={false}
                    loadingDestinationUrls={loadingDestinationUrls}
                    loadMoreDestinationUrls={loadMoreDestinationUrls}
                    onLoadMoreDestinationUrls={onLoadMoreDestinationUrls}
                />
                <div className="w-5/6 my-3">
                    <label className="ml-3 text-off-black">Total Sources: {totalSources}</label>
                    {sources.length < 1 &&
                        <NoResults
                            headerMsg="No Results Found"
                            labelMsg="You may need to configure your first source with this connection."
                        />}

                    {sources.length >= 1 &&
                        <table className="ml-3 mt-3 mb-16 table-auto h-auto w-full cursor-pointer border border-gray-lines text-off-black rounded-md bg-very-light-gray">
                            <thead className="px-3 pt-3 pb-2 font-bold uppercase text-sm bg-light-gray text-left">
                                <tr>
                                    <th className="p-3">
                                        External ID
                                        <span onClick={() => {
                                            requestSortSources("external_id", sortDirection)
                                            setSortDirection(!sortDirection)
                                            setSortByColumn("external_id")
                                        }}>
                                            {sortByColumn === "external_id" ?
                                                sortDirection ? <SortedUp /> : <SortedDown />
                                                : <SortingArrows />}
                                        </span>
                                    </th>
                                    <th className="p-3">
                                        {t("Destination")}
                                    </th>
                                    <th className="p-3">
                                        Routes
                                    </th>
                                    <th className="p-3">
                                        Update Frequency
                                    </th>
                                    <th className="p-3">
                                        Last Update
                                        <span onClick={() => {
                                            requestSortSources("last_update", sortDirection)
                                            setSortDirection(!sortDirection)
                                            setSortByColumn("last_update")
                                        }}>
                                            {sortByColumn === "last_update" ?
                                                sortDirection ? <SortedUp /> : <SortedDown />
                                                : <SortingArrows />}
                                        </span>
                                    </th>
                                    <th className="p-3">
                                        Date Created
                                        <span onClick={() => {
                                            requestSortSources("created_at", sortDirection)
                                            setSortDirection(!sortDirection)
                                            setSortByColumn("created_at")
                                        }}>
                                            {sortByColumn === "created_at" ?
                                                sortDirection ? <SortedUp /> : <SortedDown />
                                                : <SortingArrows />}
                                        </span>
                                    </th>
                                </tr>
                            </thead>
                            {loadingSources ? <tbody>
                                <tr>
                                    <td colSpan={6} className="text-center p-3">
                                        <Spinner />
                                    </td>
                                </tr>
                            </tbody> : <tbody className="text-sm">
                                {sources.map((source: Source) => {
                                    const newdate = new Date(source.created_at)
                                    return (
                                        <tr key={source.id}
                                            className="border border-gray-lines hover:bg-light-blue bg-white">
                                            <td className="p-3">
                                                {source.status === "healthy" ? "" : <AttentionIcon />}{source.external_id}
                                            </td>
                                            <td className="p-3">
                                                {source.destinations.map((dest: Destination) => {
                                                    let dest_url: string | URL;
                                                    try {
                                                        dest_url = new URL(dest.base_url).hostname
                                                    } catch (err) {
                                                        dest_url = ""
                                                    }
                                                    return (
                                                        <p key={dest.id}>{dest_url}</p>
                                                    )
                                                })}
                                            </td>
                                            <td className="p-3">
                                                {source.routing_rules.map((route: RoutingRule) => {
                                                    return (<p key={route.id}>{route.name}</p>)
                                                })}
                                            </td>
                                            <td className="p-3">
                                                {source.update_frequency}
                                            </td>
                                            <td className="p-3">
                                                {source.last_update}
                                            </td>
                                            <td className="p-3">
                                                {newdate.toString()}
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>}
                        </table>}
                    <Pagination
                        showText={true}
                        handlePageClick={onPageClicked}
                        pageCount={Math.ceil(totalSources / take)}
                        resultStart={offset + 1}
                        resultEnd={(offset + take) < totalSources ? (offset + take) : totalSources}
                        totalCount={totalSources}
                        take={take}
                        loading={!pageLoaded}
                        mainclassname="fixed left-96 right-0 bottom-16 pt-2 pr-3 pl-3 flex flex-row justify-between bg-very-light-gray opacity-90"
                    />
                </div>
            </div>
        </div>
    )
};

export default ConnectionDetailsSource;
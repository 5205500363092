import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getConnectionsList } from '../../api/connections/connectionsApi';
import { Connection, DestinationType, Destination, ProviderType, buildDestination, Organization } from '../common/ObjectTypes';
import Spinner from '../common/Spinner';
import ConnectionsList from './ConnectionsList';
import loadOrganizations from "../../api/organizations/loadOrganizations";
import loadDestinationTypes from '../../api/destinations/loadDestinationTypes';
import { page_size } from '../../api/apiUtils';
import { useKeycloakToken } from '../common/useKeycloak';
import loadProviderTypes from '../../api/connections/loadProviderTypesFilter';
import loadDestinationUrls from '../../api/destinations/loadDestinationUrls';

const ConnectionsPage = () => {
    type FilterTypes = {
        search: string,
        provider_type__in: FilterTags[],
        destination_type__in: FilterTags[],
        destination_url__in: FilterTags[],
        owner__in: FilterTags[],
        ordering: string,
    }
    type FilterTags = {
        name: string,
        id: string
    };
    const defaultConnectionsFilter = {
        provider_type__in: [],
        destination_type__in: [],
        destination_url__in: [],
        owner__in: [],
        search: "",
        ordering: "name"
    };
    const [token, showLoginModal, setShowLoginModal] = useKeycloakToken();
    const [connections, setConnections] = useState<Connection[]>([]);
    const [connectionsFilter, setConnectionsFilter] = useState<FilterTypes>(() => {
        const savedFilter = localStorage.getItem('connectionsFilter');
        return savedFilter ? JSON.parse(savedFilter) : defaultConnectionsFilter;
    });
    const [organizations, setOrganizations] = useState<Organization[]>([]);
    const [loadingOrganizations, setLoadingOrganizations] = useState(false);
    const [loadMoreOrganizations, setLoadMoreOrganizations] = useState(true);
    const [organizationOffset, setOrganizationOffset] = useState<number>(0);
    const [destinationTypes, setDestinationTypes] = useState<DestinationType[]>([]);
    const [destinationTypesOffset, setDestinationTypesOffset] = useState<number>(0);
    const [loadingDestinationTypes, setLoadingDestinationTypes] = useState(false);
    const [loadMoreDestinationTypes, setLoadMoreDestinationTypes] = useState(true);
    const [providerTypes, setProviderTypes] = useState<ProviderType[]>([]);
    const [providerTypesOffset, setProviderTypesOffset] = useState<number>(0);
    const [loadingProviderTypes, setLoadingProviderTypes] = useState(false);
    const [loadMoreProviderTypes, setLoadMoreProviderTypes] = useState(true);
    const [destinationUrls, setDestinationUrls] = useState<any[]>([]);
    const [destinationUrlOffset, setDestinationUrlOffset] = useState<number>(0);
    const [loadingDestinationUrls, setLoadingDestinationUrls] = useState(false);
    const [loadMoreDestinationUrls, setLoadMoreDestinationUrls] = useState(true);
    const [pageLoaded, setPageLoaded] = useState(false);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [offset, setOffset] = useState<number>(0);
    const [reloadConnections, setReloadConnections] = useState(true);
    const navigate = useNavigate();

    const loadOrganizationsData = async (offset: number, search_str: string) => {
        if (token && typeof token === 'string') {
            setLoadingOrganizations(true);
            const [orgs, total_orgs, error] = await loadOrganizations(token, offset, search_str, organizations);
            if (!error) {
                setOrganizations(orgs);
                if (offset + page_size >= total_orgs) {
                    setLoadMoreOrganizations(false);
                }
                setLoadingOrganizations(false);
            }
        }
    }

    const loadDestinationTypesData = async (offset: number, search_str: string) => {
        if (token && typeof token === 'string') {
            setLoadingDestinationTypes(true);
            const [dest_types, total_dest_types, error] = await loadDestinationTypes(token, offset, search_str, destinationTypes);
            if (!error) {
                setDestinationTypes(dest_types);
                if (offset + page_size >= total_dest_types) {
                    setLoadMoreDestinationTypes(false);
                }
                setLoadingDestinationTypes(false);
            }
        }
    }

    const loadProviderTypesData = async (offset: number, search_str: string) => {
        if (token && typeof token === 'string') {
            setLoadingProviderTypes(true);
            const [provider_types, total_provider_types, error] = await loadProviderTypes(token, offset, search_str, providerTypes);
            if (!error) {
                setProviderTypes(provider_types);
                if (offset + page_size >= total_provider_types) {
                    setLoadMoreProviderTypes(false);
                }
                setLoadingProviderTypes(false);
            }
        }
    }

    const loadDestinationURlsData = async (offset: number, search_str: string) => {
        if (token && typeof token === 'string') {
            const [dest_urls, total_urls, error] = await loadDestinationUrls(token, offset, search_str, destinationUrls);
            setLoadingDestinationUrls(true);
            if (!error) {
                setDestinationUrls([...new Set([...destinationUrls, ...dest_urls])]);
                if (offset + page_size >= total_urls) {
                    setLoadMoreDestinationUrls(false);
                }
                setLoadingDestinationUrls(false);
            }
        }
    }

    useEffect(() => {
        localStorage.setItem('connectionsFilter', JSON.stringify(connectionsFilter));
        const loadConnections = async () => {
            if (token && typeof token === 'string') {
                try {
                    const connectionFilterQuery = {
                        provider_type__in: connectionsFilter.provider_type__in.map(a => a.id).toString(),
                        destination_type__in: connectionsFilter.destination_type__in.map(a => a.id).toString(),
                        destination_url__in: connectionsFilter.destination_url__in.map(a => (a.id)).toString(),
                        owner__in: connectionsFilter.owner__in.map(a => a.id).toString(),
                        ordering: connectionsFilter.ordering,
                        search: connectionsFilter.search,
                        offset: offset,
                        previous: process.env.REACT_APP_BASE_URL_LOCAL + "/connections/?pager=limit&limit=" + page_size + "&offset=" + (offset - page_size),
                        next: process.env.REACT_APP_BASE_URL_LOCAL + "/connections/?pager=limit&limit=" + page_size + "&offset=" + (offset + page_size)
                    }
                    const temp = await getConnectionsList(token, connectionFilterQuery)
                    setConnections(temp.results)
                    setTotalCount(temp.count);
                    setReloadConnections(false);
                    setPageLoaded(true);
                } catch (err) {
                    console.log(err)
                }
            }
        }

        if (reloadConnections) loadConnections();
        if (!pageLoaded) {
            loadOrganizationsData(organizationOffset, "");
            loadProviderTypesData(providerTypesOffset, "");
            loadDestinationTypesData(destinationTypesOffset, "");
            loadDestinationURlsData(destinationUrlOffset, "");
        }
    }, [token, reloadConnections, connectionsFilter]);

    const handleGlobalSearch = (searchSubStr: string) => {
        setConnectionsFilter((prevFilter: any) => ({
            ...prevFilter,
            search: searchSubStr
        }));
        setReloadConnections(true);
    }

    const handleChangeProviderTypeSearchBar = (e: any) => {
        if (e.target.value.length >= 3 || e.target.value.length === 0) {
            loadProviderTypesData(providerTypesOffset, e.target.value);
        }
    }

    const handleLoadMoreProviderTypes = () => {
        setProviderTypesOffset(providerTypesOffset + page_size);
        loadProviderTypesData(providerTypesOffset + page_size, "");
    }

    const handleFilterProviderType = (e: any) => {
        let tempArray: FilterTags[] = connectionsFilter.provider_type__in
        if (tempArray.map(a => a.id).includes(e.target.value)) {
            let removed: FilterTags[] = []
            for (var item in tempArray) {
                if (tempArray[item].id !== e.target.value) {
                    removed.push(tempArray[item])
                }

            }
            setConnectionsFilter((prevFilter: any) => ({
                ...prevFilter,
                [e.target.id]: removed
            }))
        } else {
            const newTag = {
                name: e.target.name,
                id: e.target.value
            }
            tempArray.push(newTag)
            setConnectionsFilter((prevFilter: any) => ({
                ...prevFilter,
                [e.target.id]: tempArray
            }))
        }
        setReloadConnections(true);
    }

    const handleChangeDestinationUrlSearchBar = (e: any) => {
        if (e.target.value.length >= 3 || e.target.value.length === 0) {
            loadDestinationURlsData(destinationUrlOffset, e.target.value);
        }
    }

    const handleFilterDestinationUrl = (e: any) => {
        let tempArray: FilterTags[] = connectionsFilter.destination_url__in
        if (tempArray.map(a => a.id).includes(e.target.value)) {
            let removed: FilterTags[] = []
            for (var item in tempArray) {
                if (tempArray[item].id !== e.target.value) {
                    removed.push(tempArray[item])
                }

            }
            setConnectionsFilter((prevFilter: any) => ({
                ...prevFilter,
                [e.target.id]: removed
            }))
        } else {
            const newTag = {
                name: e.target.name,
                id: e.target.value
            }
            tempArray.push(newTag)
            setConnectionsFilter((prevFilter: any) => ({
                ...prevFilter,
                [e.target.id]: tempArray
            }))
        }
        setReloadConnections(true);
    }

    const handleLoadMoreDestinationUrls = () => {
        setDestinationUrlOffset(destinationUrlOffset + page_size);
        loadDestinationURlsData(destinationUrlOffset + page_size, "");
    }

    const handleFilterDestinationType = (e: any) => {
        let tempArray: FilterTags[] = connectionsFilter.destination_type__in
        if (tempArray.map(a => a.id).includes(e.target.value)) {
            let removed: FilterTags[] = []
            for (var item in tempArray) {
                if (tempArray[item].id !== e.target.value) {
                    removed.push(tempArray[item])
                }

            }
            setConnectionsFilter((prevFilter: any) => ({
                ...prevFilter,
                [e.target.id]: removed
            }))
        } else {
            const newTag = {
                name: e.target.name,
                id: e.target.value
            }
            tempArray.push(newTag)
            setConnectionsFilter((prevFilter: any) => ({
                ...prevFilter,
                [e.target.id]: tempArray
            }))
        }
        setReloadConnections(true);
    }

    const handleChangeDestinationTypeSearchBar = (e: any) => {
        if (e.target.value.length >= 3 || e.target.value.length === 0) {
            loadDestinationTypesData(destinationTypesOffset, e.target.value);
        }
    }

    const handleLoadMoreDestinationTypes = () => {
        setDestinationTypesOffset(destinationTypesOffset + page_size);
        loadDestinationTypesData(destinationTypesOffset + page_size, "");
    }

    const handleFilterOwner = (e: any) => {
        let tempArray: FilterTags[] = connectionsFilter.owner__in
        if (tempArray.map(a => a.id).includes(e.target.value)) {
            let removed: FilterTags[] = []
            for (var item in tempArray) {
                if (tempArray[item].id !== e.target.value) removed.push(tempArray[item])
            }
            setConnectionsFilter((prevFilter: any) => ({
                ...prevFilter,
                [e.target.id]: removed
            }))
        } else {
            let newTag = {
                name: e.target.name,
                id: e.target.value
            }
            tempArray.push(newTag)
            setConnectionsFilter((prevFilter: any) => ({
                ...prevFilter,
                [e.target.id]: tempArray
            }))
        }
        setReloadConnections(true);
    }

    const handleChangeOrganizationSearchBar = (e: any) => {
        if (e.target.value.length >= 3 || e.target.value.length === 0) {
            loadOrganizationsData(organizationOffset, e.target.value);
        }
    }

    const handleLoadMoreOrganizations = () => {
        setOrganizationOffset(organizationOffset + page_size);
        loadOrganizationsData(organizationOffset + page_size, "");
    }

    const handleRemoveTag = (e: any) => {
        if (connectionsFilter.provider_type__in.map(a => a.id).includes(e)) {
            let tempArray: FilterTags[] = connectionsFilter.provider_type__in
            let removed: FilterTags[] = []
            for (var item in tempArray) {
                if (tempArray[item].id !== e) removed.push(tempArray[item])
            }
            setConnectionsFilter((prevFilter: any) => ({
                ...prevFilter,
                provider_type__in: removed
            }))
        } else if (connectionsFilter.destination_url__in.map(a => a.id).includes(e)) {
            let tempArray: FilterTags[] = connectionsFilter.destination_url__in
            let removed: FilterTags[] = []
            for (item in tempArray) {
                if (tempArray[item].id !== e) removed.push(tempArray[item])
            }
            setConnectionsFilter((prevFilter: any) => ({
                ...prevFilter,
                destination_url__in: removed
            }))
        } else if (connectionsFilter.destination_type__in.map(a => a.id).includes(e)) {
            let tempArray: FilterTags[] = connectionsFilter.destination_type__in
            let removed: FilterTags[] = []
            for (item in tempArray) {
                if (tempArray[item].id !== e) removed.push(tempArray[item])
            }
            setConnectionsFilter((prevFilter: any) => ({
                ...prevFilter,
                destination_type__in: removed
            }))
        } else if (connectionsFilter.owner__in.map(a => a.id).includes(e)) {
            let tempArray: FilterTags[] = connectionsFilter.owner__in
            let removed: FilterTags[] = []
            for (item in tempArray) {
                if (tempArray[item].id !== e) removed.push(tempArray[item])
            }
            setConnectionsFilter((prevFilter: any) => ({
                ...prevFilter,
                owner__in: removed
            }))
        }
        setReloadConnections(true);
    }

    const handleResetAllFilters = () => {
        setConnectionsFilter({
            owner__in: [],
            destination_type__in: [],
            destination_url__in: [],
            provider_type__in: [],
            search: "",
            ordering: ""
        });
        setReloadConnections(true);
    }

    const handleSortTable = (orderBy: string, sortDirection: boolean) => {
        const ordering = sortDirection ? orderBy : "-" + orderBy;
        setConnectionsFilter((prevFilter: any) => ({
            ...prevFilter,
            ordering: ordering
        }))
        setReloadConnections(true);
    }

    const handleSelectConnection = (connection_id: string) => {
        navigate("/connections/" + connection_id + "/overview")
    }

    const handlePageClick = (e: any) => {
        setOffset(e.selected * page_size);
        setReloadConnections(true);
    }

    const handleClickAdd = () => {
        navigate("/connections/add")
    }

    const handleToggleDestinationDetailsModal = async (dest_id: string) => {
        if (dest_id && token && typeof token === 'string') {
            // Append dest_id to the current URL
            navigate(`/destination/${dest_id}/details`, { replace: false });
        }
    };

    return !pageLoaded ?
        (
            <Spinner />
        ) : (
            <div>
                <ConnectionsList
                    connections={connections}
                    reloadConnections={reloadConnections}
                    organizations={organizations}
                    destinationUrls={destinationUrls}
                    destinationTypes={destinationTypes}
                    providerTypes={providerTypes}
                    onSelectConnection={handleSelectConnection}
                    onChangeGlobalSearch={handleGlobalSearch}
                    onChangeDestinationUrlSearchBar={handleChangeDestinationUrlSearchBar}
                    onChangeDestinationUrl={handleFilterDestinationUrl}
                    onLoadMoreDestinationUrls={handleLoadMoreDestinationUrls}
                    loadingDestinationUrls={loadingDestinationUrls}
                    loadMoreDestinationUrls={loadMoreDestinationUrls}
                    onChangeDestinationTypeSearchBar={handleChangeDestinationTypeSearchBar}
                    onChangeDestinationType={handleFilterDestinationType}
                    loadingDestinationTypes={loadingDestinationTypes}
                    loadMoreDestinationTypes={loadMoreDestinationTypes}
                    onLoadMoreDestinationTypes={handleLoadMoreDestinationTypes}
                    onChangeProviderTypeSearchBar={handleChangeProviderTypeSearchBar}
                    onChangeProviderType={handleFilterProviderType}
                    loadingProviderTypes={loadingProviderTypes}
                    loadMoreProviderTypes={loadMoreProviderTypes}
                    onLoadMoreProviderTypes={handleLoadMoreProviderTypes}
                    onChangeOwner={handleFilterOwner}
                    onChangeOwnerSearchBar={handleChangeOrganizationSearchBar}
                    loadingOrganizations={loadingOrganizations}
                    loadMoreOrganizations={loadMoreOrganizations}
                    onLoadMoreOrganizations={handleLoadMoreOrganizations}
                    onRemoveTag={handleRemoveTag}
                    onResetFilters={handleResetAllFilters}
                    filters={connectionsFilter.owner__in
                        .concat(connectionsFilter.destination_type__in, connectionsFilter.provider_type__in, connectionsFilter.destination_url__in)}
                    globalSearchStr={connectionsFilter.search}
                    requestSort={handleSortTable}
                    onViewDetails={handleToggleDestinationDetailsModal}
                    onPageClicked={handlePageClick}
                    totalCount={totalCount}
                    pageLoaded={pageLoaded}
                    take={page_size}
                    offset={offset}
                    onClickAdd={handleClickAdd}
                />
            </div>

        );
};

export default ConnectionsPage;
import React, { ChangeEventHandler, MouseEventHandler } from "react";
import NewConnectionStepOne from "./NewConnectionStepOne";
import { ProviderType, Organization, Destination, NewIntegration, DestinationType } from "../common/ObjectTypes";
import NewConnectionStepTwo from "./NewConnectionStepTwo";
import NewConnectionStepThree from "./NewConnectionStepThree";

type AddConnectionDetailsProps = {
    initialConnection: NewIntegration,
    errors: any,
    rsjfErrors: any,
    providerTypes: ProviderType[],
    reloadProviderTypes: boolean,
    destinationTypes: DestinationType[],
    organizations: Organization[],
    loadMoreOrgs: Function,
    orgInputChange: Function,
    destinations: Destination[],
    selectedDestinations: Destination[],
    onChangeOwnerSearchBar: ChangeEventHandler,
    loadingOrganizations: boolean,
    loadMoreOrganizations: boolean,
    onLoadMoreOrganizations: Function,
    onChangeStateFilter: ChangeEventHandler,
    onChangeTypeFilter: ChangeEventHandler,
    onChangeOwnerFilter: ChangeEventHandler,
    onChangeDestinationUrlSearchBar: ChangeEventHandler,
    onChangeDestinationTypeSearchBar: ChangeEventHandler,
    onLoadMoreDestinationTypes: Function,
    loadingDestinationTypes: boolean,
    loadMoreDestinationTypes: boolean,
    onGlobalDestinationFilter: Function,
    onRemoveDestinationFilterTag: any,
    onResetDestinationFilters: any,
    onDestinationPageClicked: Function,
    totalDestinationCount: number,
    destinationOffset: number,
    filters: any[],
    onChangeEndpoint: Function,
    onChangeSchemaForm: Function,
    onChangeName: Function,
    onSelectOwner: Function,
    onSelectProviderType: Function,
    onSearchProviderType: Function,
    onPageClickProviderTypes: MouseEventHandler,
    providerTypesOffset: number,
    providerTypesTotal: number,
    onSelectDestination: Function,
    onRemoveDestination: Function,
    selectedProviderType: ProviderType,
    isProviderTypeSelected: boolean,
    onClickNext: Function,
    currentStep: number,
    onCancel: MouseEventHandler,
    pageLoaded: boolean,
    onAddDestination: Function,
    onChangeProviderEnabled: Function,
}

const AddConnectionDetails = ({
    initialConnection,
    errors,
    rsjfErrors,
    providerTypes,
    reloadProviderTypes,
    destinationTypes,
    organizations,
    loadMoreOrgs,
    orgInputChange,
    destinations,
    selectedDestinations,
    filters,
    onChangeOwnerSearchBar,
    loadingOrganizations,
    loadMoreOrganizations,
    onLoadMoreOrganizations,
    onChangeStateFilter,
    onChangeTypeFilter,
    onChangeOwnerFilter,
    onChangeDestinationUrlSearchBar,
    onChangeDestinationTypeSearchBar,
    onLoadMoreDestinationTypes,
    loadingDestinationTypes,
    loadMoreDestinationTypes,
    onGlobalDestinationFilter,
    onRemoveDestinationFilterTag,
    onResetDestinationFilters,
    onDestinationPageClicked,
    totalDestinationCount,
    destinationOffset,
    onChangeEndpoint,
    onChangeSchemaForm,
    onChangeName,
    onSelectOwner,
    onSelectProviderType,
    onSearchProviderType,
    onPageClickProviderTypes,
    providerTypesOffset,
    providerTypesTotal,
    onSelectDestination,
    onRemoveDestination,
    selectedProviderType,
    isProviderTypeSelected,
    onClickNext,
    currentStep,
    onCancel,
    pageLoaded,
    onAddDestination,
    onChangeProviderEnabled
}: AddConnectionDetailsProps) => {
    return (
        <div className="pb-20 relative">
            <div className="bg-white shadow static inset-x-0 px-12 pt-5 -mt-5 -mx-5 xl:-mx-12">
                <div className="flex flex-col">
                    {currentStep === 1 && <div>
                        <h1 className="text-3xl font-semibold mb-3">Create a Connection</h1>
                    </div>}
                    {currentStep > 1 && <div>
                        <h1 className="text-3xl font-semibold mb-3">New {selectedProviderType.name} Connection</h1>
                        {/* tabs for provider and destinations */}
                        <div className="w-full flex flex-row font-semibold text-medium-gray">
                            <label className={currentStep === 2 ? "border-2 border-white border-b-status-green text-dark-gray mr-3 py-3 cursor-pointer" : "border-2 border-white mr-3 py-3 cursor-pointer"}
                                onClick={() => onClickNext(2)}>
                                Connect Provider
                            </label>
                            <label className={currentStep > 2 ? "border-2 border-white border-b-status-green text-dark-gray mr-3 py-3 cursor-pointer" : "border-2 border-white mr-3 py-3 cursor-pointer"}
                                onClick={() => onClickNext(3)}
                            >
                                Connect Destinations
                            </label>
                        </div>
                    </div>}
                </div>

            </div>
            {/* This is the step by step */}
            <div className="px-12 pt-5 -mt-5 -mx-5 xl:-mx-12">
                {currentStep === 1 &&
                    <NewConnectionStepOne
                        providerTypes={providerTypes}
                        reloadProviderTypes={reloadProviderTypes}
                        onSelectProviderType={onSelectProviderType}
                        onSearchProviderType={onSearchProviderType}
                        onPageClickProviderTypes={onPageClickProviderTypes}
                        providerTypesTotal={providerTypesTotal}
                        providerTypesOffset={providerTypesOffset}
                        pageLoaded={pageLoaded}
                    />}
                {currentStep === 2 &&
                    <NewConnectionStepTwo
                        initialConnection={initialConnection}
                        errors={errors}
                        rsjfErrors={rsjfErrors}
                        onChangeEndpoint={onChangeEndpoint}
                        onChangeSchemaForm={onChangeSchemaForm}
                        onChangeName={onChangeName}
                        onSelectOwner={onSelectOwner}
                        selectedProviderType={selectedProviderType}
                        organizations={organizations}
                        loadMoreOrgs={loadMoreOrgs}
                        orgInputChange={orgInputChange}
                        onChangeProviderEnabled={onChangeProviderEnabled}
                    />}
                {currentStep === 3 &&
                    <NewConnectionStepThree
                        destinations={destinations}
                        destinationTypes={destinationTypes}
                        organizations={organizations}
                        selectedDestinations={selectedDestinations}
                        onSelectDestination={onSelectDestination}
                        onRemoveDestination={onRemoveDestination}
                        filters={filters}
                        onDestinationPageClicked={onDestinationPageClicked}
                        totalDestinationCount={totalDestinationCount}
                        destinationOffset={destinationOffset}
                        onChangeStateFilter={onChangeStateFilter}
                        onChangeTypeFilter={onChangeTypeFilter}
                        onChangeDestinationTypeSearchBar={onChangeDestinationTypeSearchBar}
                        loadingDestinationTypes={loadingDestinationTypes}
                        loadMoreDestinationTypes={loadMoreDestinationTypes}
                        onLoadMoreDestinationTypes={onLoadMoreDestinationTypes}
                        onChangeOwnerFilter={onChangeOwnerFilter}
                        onChangeOwnerSearchBar={onChangeOwnerSearchBar}
                        onLoadMoreOrganizations={onLoadMoreOrganizations}
                        loadingOrganizations={loadingOrganizations}
                        loadMoreOrganizations={loadMoreOrganizations}
                        onChangeDestinationUrlSearchBar={onChangeDestinationUrlSearchBar}
                        onGlobalDestinationFilter={onGlobalDestinationFilter}
                        onRemoveDestinationFilterTag={onRemoveDestinationFilterTag}
                        onResetDestinationFilters={onResetDestinationFilters}
                        pageLoaded={pageLoaded}
                        onAddDestination={onAddDestination}
                    />}
            </div>
            <div className="fixed flex flex-row-reverse bottom-0 right-0 pr-5 py-5 bg-very-light-gray w-full shadow-lg">
                <button className={isProviderTypeSelected ? "opacity-100 bg-dark-green hover:bg-status-green text-white rounded py-2 px-8 ml-2 shadow" :
                    "opacity-100 bg-dark-green text-gray-lines rounded py-2 px-8 ml-2 shadow"}
                    onClick={() => onClickNext(currentStep + 1)}
                    disabled={!isProviderTypeSelected}
                >
                    {currentStep === 3 ? "Finish" : "Next"}
                </button>
                <button className="bg-very-light-gray text-medium-gray rounded py-2 px-3 mx-2"
                    type="button"
                    onClick={onCancel}
                >
                    Cancel
                </button>
            </div>
        </div>
    )
}

export default AddConnectionDetails;